import React from "react";
import "../styles/AboutSection.css";

const AboutSection = () => {
    return (
        <section className="about-section">
            {/* Heading */}
            <h2 className="heading">Über Skilt</h2>
            <div className="divider"></div>

            {/* Text Content */}
            <div className="text-box">
                <p className="text">
                Skilt macht theoretische Inhalte der Handwerks-Ausbildung verständlich
                und zugänglich.
                </p>
                <p className="text">
                Die kurzen Wissenshäppchen und interaktiven Quizzes erleichtern das
                Lernen und sind optimal auf die Bedürfnisse der digitalen Generation
                abgestimmt.
                </p>
                <p className="text">
                Skilt SHK wird ab <strong>Januar 2025</strong> im Google Play Store verfügbar sein.
                Die App richtet sich an Auszubildende im Beruf Anlagenmechaniker*in SHK und wird künftig auch für weitere Handwerksberufe verfügbar sein.
                </p>
            </div>
        </section>
    );
};

export default AboutSection;
