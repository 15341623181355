import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Footer.css"; // Import the separated styles

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleStartClick = () => {
        if (location.pathname === "/") {
            // If already on the main page, scroll to the StartSection
            const startSection = document.getElementById("start-section");
            if (startSection) {
                startSection.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            // If on a different page, navigate to the main page and then scroll
            navigate("/", { replace: true });
            setTimeout(() => {
                const startSection = document.getElementById("start-section");
                if (startSection) {
                    startSection.scrollIntoView({ behavior: "smooth" });
                }
            }, 100); // Allow some delay for the page to load
        }
    };

    return (
        <footer className="footer">
            <nav>
                {/* Start Link */}
                <button onClick={handleStartClick} className="footer-link">
                    Start
                </button>

                {/* Kontakt - Email Link */}
                <a href="mailto:info@skilt.app" className="footer-link">
                    Kontakt
                </a>

                {/* Impressum Link */}
                <a href="/impressum" className="footer-link">
                    Impressum
                </a>
            </nav>
        </footer>
    );
};

export default Footer;
